@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
body {
  font-family: 'Inter', sans-serif;
  background-color: #f3f4f6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.badge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: #e5e7eb;
  color: #4b5563;
  text-decoration: none;
  transition: background-color 0.2s;
}

.badge:hover {
  background-color: #d1d5db;
}

.scroll-area {
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 #f1f5f9;
}

.scroll-area::-webkit-scrollbar {
  width: 8px;
}

.scroll-area::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.scroll-area::-webkit-scrollbar-thumb {
  background-color: #cbd5e0;
  border-radius: 20px;
  border: 3px solid #f1f5f9;
}

/* New styles for interactive elements */
.step-button {
  transition: background-color 0.2s;
}

.step-button:hover {
  opacity: 0.8;
}

/* Add any additional custom styles here */

.text-blue-500 {
  color: #3b82f6;
}

.underline {
  text-decoration: underline;
}

@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

.stars, .twinkling {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
}

.stars {
background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
z-index:0;
}

.twinkling{
background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;
z-index:1;
animation:move-twink-back 200s linear infinite;
}


/* Add these new styles for the navigation */
.backdrop-blur-xl {
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
}

/* Custom select styling */
select option {
  background-color: #1a1a1a;
  color: white;
  padding: 8px;
}

/* Add smooth transition for header background */
header {
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

/* Add styles for focus states */
button:focus, select:focus {
  outline: 2px solid rgba(255, 255, 255, 0.3);
}

/* Add custom animation for icon rotation */
.group:hover .rotate-icon {
  transform: rotate(180deg);
}

/* Improve button press effect */
button:active {
  transform: scale(0.98);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Improved font rendering */
@layer base {
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: 'Inter', sans-serif;
  }
 
  .font-display {
    font-family: 'Poppins', sans-serif;
  }
  
  .font-body {
    font-family: 'Inter', sans-serif;
  }
  
}

/* Custom button styles */
@layer components {
  .btn {
    @apply inline-flex items-center justify-center
           px-4 py-2 rounded-lg font-medium
           transition-all duration-200 ease-in-out
           transform hover:scale-[1.02] active:scale-[0.98]
           shadow-lg hover:shadow-xl;
  }

  .btn-primary {
    @apply bg-gradient-to-r from-blue-500 to-blue-600 
           hover:from-blue-600 hover:to-blue-700
           text-white;
  }

  .btn-secondary {
    @apply bg-gradient-to-r from-gray-500 to-gray-600
           hover:from-gray-600 hover:to-gray-700
           text-white;
  }
}

/* Existing styles... */